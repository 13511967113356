import React, { useEffect } from "react";
import { Link } from "gatsby";
import { RandomEmoji } from "../../common/RandomEmoji";
import { Hero } from "../../common/Hero";
import { BlockContent } from "../../common/BlockContent";
import { MediaEmbed } from "../../common/MediaEmbed";
import { ImageMediaGrid } from "../../common/ImageMediaGrid";
// import { Grid } from "../../common/Grid";
import classNames from "classnames";

import * as styles from "./Lesson.module.scss";

// todo: add url change on slide change
// https://spacejelly.dev/posts/how-to-trigger-a-function-when-scrolling-to-an-element-in-react-intersection-observer/

const Lesson = ({
  lesson,
  order,
  currentLesson,
  nextLessonLink,
  nextLessonTitle,
  totalLessons,
}) => {
  const { title, _rawDescription, mainImage, body, _rawBody, slides } = lesson;
  // console.log(lesson);
  const nextSlide = currentLesson + 1;
  const isNextSlide = nextSlide <= totalLessons;

  useEffect(() => {
    // document.querySelector("html").style = "height:100%;";
    // document.body.style = "height:100%;";
    // return () => {
    //   document.querySelector("html").style = "height:auto;";
    //   document.body.style = "height:auto;";
    // };
  }, []);

  // console.log("order", order);
  return (
    <article className={styles.lesson}>
      <div className={styles.homeEmoji}>
        <Link to="/">
          <RandomEmoji index={order} />
        </Link>
      </div>
      <section>
        <h1>{title}</h1>
        <Hero image={lesson.mainImage.asset.gatsbyImageData} alt="" />
        <BlockContent className={styles.bodyAlone} blocks={_rawDescription} />
      </section>
      {slides.map((slide, index) => {
        // console.log(slide);
        const cls = classNames({
          [styles.even]: index % 2 !== 0,
          [styles.odd]: index % 2 === 0,
        });

        const slideBodyCls =
          slide.iframe || slide.media.length > 0
            ? styles.bodyFriends
            : styles.bodyAlone;

        return (
          <section key={index} className={cls}>
            {slide.title && <h2>{slide.title}</h2>}
            <div className={styles.slideElementsContainer}>
              <BlockContent className={slideBodyCls} blocks={slide._rawBody} />

              {slide.iframe && (
                <MediaEmbed
                  mediaUrl={slide.iframe}
                  className={styles.iframeContainer}
                />
              )}
              {slide.media.length > 0 && (
                <div className={styles.media}>
                  <ImageMediaGrid
                    items={slide.media}
                    defaultAlt={slide.title}
                  />
                </div>
              )}
            </div>
          </section>
        );
      })}
      <section className={styles.final}>
        {isNextSlide ? (
          <>
            <h2>
              <RandomEmoji index={order + 1} />{" "}
              <Link to={`/${nextLessonLink}`}>
                Next Lesson: {nextLessonTitle}
              </Link>
            </h2>
          </>
        ) : (
          <>
            <h2>
              <RandomEmoji /> You have completed all the lessons
            </h2>
            <h2>
              <RandomEmoji /> <Link to="/">Go back to the homepage</Link>
            </h2>
          </>
        )}
      </section>
    </article>
  );
};

export { Lesson };
