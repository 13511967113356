import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/common/seo";
import { Lesson } from "../components/pageLayout/Lesson";
import { toPlainText } from "../utils/common";
import { trimLongString } from "../utils/common";

export const query = graphql`
  query SlideshowTemplateQuery($id: String!, $nextSlideshowId: String!) {
    slideshow: sanitySlideshow(id: { eq: $id }) {
      id
      title
      shortTitle
      order
      _rawDescription
      description {
        _type
        _key
        children {
          _key
          _type
          marks
          text
        }
      }
      slug {
        current
      }
      publishedAt
      mainImage {
        asset {
          id
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          url
        }
      }
      slides {
        _rawBody
        iframe
        media {
          ... on SanityImageItem {
            caption
            asset {
              gatsbyImageData
              altText
              description
            }
          }
          ... on SanityVideoItem {
            url
            caption
          }
        }
        title
      }
    }
    nextSlideshow: sanitySlideshow(id: { eq: $nextSlideshowId }) {
      title
      shortTitle
      slug {
        current
      }
      publishedAt
      mainImage {
        asset {
          id
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          url
        }
      }
    }
  }
`;

const SlideshowTemplate = ({ data, pageContext }) => {
  // console.log(data);
  // console.log(pageContext);
  const slideshow = data.slideshow;
  const nextSlideshow = data.nextSlideshow;
  const nextLink = nextSlideshow ? nextSlideshow.slug.current : null;
  const nextTitle = nextSlideshow ? nextSlideshow.title : null;
  // console.log(slideshow);
  const seoDescription = slideshow.description
    ? trimLongString(toPlainText(slideshow.description), 300)
    : "";
  const seoImage = slideshow.mainImage?.asset?.url;

  return (
    <>
      <Seo
        title={slideshow.title}
        image={seoImage}
        // description={seoDescription}
      />
      <Lesson
        lesson={slideshow}
        totalLessons={pageContext.totalSlideshows}
        currentLesson={pageContext.currentSlideshow}
        nextLessonLink={nextLink}
        nextLessonTitle={nextTitle}
        order={slideshow.order}
      />
    </>
  );
};

export default SlideshowTemplate;
