import { Figure } from "./figure";
import { Code } from "./code";

const serializers = {
  types: {
    figure: Figure,
    code: Code,
  },
};

export default serializers;
