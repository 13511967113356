import BaseBlockContent from "@sanity/block-content-to-react";
import React from "react";
import clientConfig from "../../../client-config";
import serializers from "./serializers";

import * as styles from "./Blockcontent.module.scss";

const BlockContent = ({ blocks, className }) => {
  return (
    <div className={className}>
      <BaseBlockContent
        className={styles.content}
        blocks={blocks}
        serializers={serializers}
        {...clientConfig.sanity}
      />
    </div>
  );
};

export { BlockContent };
