import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import {
  docco,
  tomorrowNight,
} from "react-syntax-highlighter/dist/esm/styles/hljs";

export function Code({ node }) {
  if (!node || !node.code) {
    return null;
  }
  const { language, code } = node;
  return (
    <SyntaxHighlighter language={language || "text"} style={tomorrowNight}>
      {code}
    </SyntaxHighlighter>
  );
}
