import React from "react";

import * as styles from "./CodePen.module.scss";

const CodePen = ({ url }) => {
  const embedPrefix = "https://codepen.io/";
  const splitUrl = url.replace(/\/$/, "").split("/");
  const username = splitUrl[splitUrl.length - 3];
  const pen = splitUrl[splitUrl.length - 1];
  const settings =
    "?default-tab=html%2Cresult&editable=true&theme-id=tomorrowNight";

  const codepenUrl = `${embedPrefix}/${username}/embed/${pen}${settings}`;

  // const pt = "56.25%";
  const codeWidth = "100%";
  const codeHeight = "100%";

  return (
    <div
      width={codeWidth}
      height={codeHeight}
      // style={{
      //   paddingBottom: `${pt}`,
      // }}
      className={styles.codePen}
    >
      <iframe
        title="codepen"
        width={codeWidth}
        height={codeHeight}
        src={codepenUrl}
        frameBorder="no"
        loading="lazy"
        allowtransparency="true"
        // sandbox="allow-same-origin"
        allowFullScreen={true}
      ></iframe>

      {/* <iframe
            height="300"
            style="width: 100%;"
            scrolling="no"
            title="Untitled"
            src="https://codepen.io/amcc/embed/jOKKXPW?default-tab=html%2Cresult&editable=true&theme-id=dark"
            frameborder="no"
            loading="lazy"
            allowtransparency="true"
            allowfullscreen="true"
          >
            See the Pen{" "}
            <a href="https://codepen.io/amcc/pen/jOKKXPW">Untitled</a> by amcc (
            <a href="https://codepen.io/amcc">@amcc</a>) on{" "}
            <a href="https://codepen.io">CodePen</a>.
          </iframe> */}
    </div>
  );
};

export { CodePen };
