// extracted by mini-css-extract-plugin
export var homeEmoji = "Lesson-module--home-emoji--1-ZeI";
export var lesson = "Lesson-module--lesson--17Gki";
export var even = "Lesson-module--even--3917V";
export var odd = "Lesson-module--odd--c2nsG";
export var final = "Lesson-module--final--3o4uV";
export var slideElementsContainer = "Lesson-module--slide-elements-container--1SD3l";
export var bodyFriends = "Lesson-module--body-friends--236lG";
export var bodyAlone = "Lesson-module--body-alone--2QFTM";
export var test = "Lesson-module--test--z0pgt";
export var media = "Lesson-module--media--1gfxa";
export var iframeContainer = "Lesson-module--iframe-container--3IokB";